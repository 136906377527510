


body::-webkit-scrollbar, div::-webkit-scrollbar {
    width: 0 !important;
}

/* @font-face {
    font-family: "Montserrat", sans-serif;
} */

html {
    scroll-behavior: smooth;
    font-family: "Montserrat", sans-serif !important;
}

body {
    font-size: .9rem !important;
    font-family: "Montserrat", sans-serif !important;
    height: 100vh;
    width: 100%;
    overflow: hidden;
} 

/* .fw-bold {
    font-family: Montserrat !important;
} */

label {
    font-size: .9rem !important;
    margin-bottom: 2px !important;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    padding: 0 !important;
}

.bshadow {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

button.bshadow:hover {
    box-shadow: none;
}

.bradius {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.btn-success {
    background-color: #f09502 !important;
    border: none !important;
}

.btn-success:hover {
    background-color: #d28202 !important;
}

.btn-outline-success {
    color: #f09502;
    border-color: #f09502;
}

.btn-outline-success:hover {
    background-color: #d28202 !important;
    border-color: #f09502;
}

.swal2-styled.swal2-confirm {
    background-color: #f09502 !important;
}

.btn-outline-success:disabled {
    border-color: #f09502 !important;
    color: #f09502 !important;
}

.parpadea {
    animation-name: parpadeo;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name:parpadeo;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}
html, body { height: 100%; }
body { margin: 0; font-family:"Montserrat", sans-serif; }
@import '~mdb-angular-ui-kit/assets/scss/mdb.scss';

.modal1 {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.5px);
    background-color: rgba(0, 0, 0, 0.3);
}


.swal2-container{
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.5px);
}
html, body { height: 100%; }
body { margin: 0; font-family: "Montserrat", sans-serif !important; }


.swal2-loader{
    border-color: #f09502 transparent #f09502 transparent !important;
}



html, body { height: 100%; }
body { margin: 0; font-family: "Montserrat", sans-serif !important; }

.mat-dialog-container {
    background-color: white;
    border-radius: 10px !important;
    color: black
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .50);
}

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.backgroundProgress .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #b0b0b0 !important; /* Cambia '#3f51b5' al color que prefieras */

}


.frontProgress .mat-progress-spinner circle, .mat-spinner circle {
    stroke: #f09502 !important; /* Cambia '#3f51b5' al color que prefieras */
}


.custom-checkbox .mat-checkbox-frame {
    border-color: #b0b0b0 !important;
  }

  .custom-checkbox.mat-checkbox-checked .mat-checkbox-background,
.custom-checkbox.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #f09502;
}

.mat-input-element {
    caret-color: #f09502;
}

@import '~@ng-select/ng-select/themes/default.theme.css';

.ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 150px !important;
}

.gray-input {
    color: gray;
  }
  
  .mat-form-field-appearance-fill .mat-form-field-underline::after {
    background-color: gray;
  }

  .form-check-input:checked {
    background-color: #ff9600 !important;
    border-color: #ff9600 !important;
}

.form-check-input:focus {
    border-color: #ff9600 !important;
    box-shadow: 0 0 0 .25rem rgb(255 150 0 / 25%);
}

.form-control:focus {
    border-color: #ff9600 !important;
    box-shadow: 0 0 0 .25rem rgb(255 150 0 / 25%);
}

.ng-select.ng-select-focused .ng-select-container {
    border-color: #ff9600 !important;
    box-shadow: 0 0 0 .25rem rgb(255 150 0 / 25%);
}

.mat-tooltip {
    font-size: 14px; /* Cambia esto al tamaño de fuente que desees */
  }

.logout-txt{
    cursor: pointer;
}

.modal-divider{
    width: 75px; 
    height: 1px; 
    background-color: #848484;
}

@media screen and (max-width: 480px){
    .logout-txt{
        font-size: 12px;
    }
    .text-phone{
        font-size: 10px;
        text-align: center;
        font-weight: 500;
    }
    .modal-divider{
        width: 50px;
    }
}

.custom-dialog-container{
    max-width: 100% !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0!important;
    margin-bottom: .5rem!important;
    /*font-weight: 500;*/
    line-height: 1.2 !important;
    color: var(--bs-heading-color);
}

.h5, h5 {
    font-size: 1.25rem !important;
}

.mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
    font-size: 1.25rem !important;
    font-family: "Montserrat", sans-serif !important;
    margin-top: 0 !important;
    margin-bottom: .5rem !important;
}

.mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
    font-family: "Montserrat", sans-serif !important;
    font-size: 2rem !important;
}

.mat-h3, .mat-subheading-2, .mat-typography .mat-h3, .mat-typography .mat-subheading-2, .mat-typography h3 {
    font: 400 1.75rem "Montserrat", sans-serif !important;
    font-family: "Montserrat", sans-serif !important;

}

.mat-h4, .mat-subheading-1, .mat-typography .mat-h4, .mat-typography .mat-subheading-1, .mat-typography h4 {
    font-family: "Montserrat", sans-serif !important;
    font-size: 1.5rem !important;
    line-height: 1.2 !important;
}

@media screen and (max-width: 767px) {
    .mat-h2, .mat-title, .mat-typography .mat-h2, .mat-typography .mat-title, .mat-typography h2 {
    font-size: 1.5rem !important;
    }

    .mat-h3, .mat-subheading-2, .mat-typography .mat-h3, .mat-typography .mat-subheading-2, .mat-typography h3 {
        font-size: 1.375rem !important;
    }

    .mat-h4, .mat-subheading-1, .mat-typography .mat-h4, .mat-typography .mat-subheading-1, .mat-typography h4 {
        font-size: 1.25rem !important;
    }

    .mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
        font-size: 1rem !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
    white-space: normal !important;
    word-break: break-word !important;
  }
.apexcharts-pie-label, .apexcharts-datalabels, .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value {
    cursor: default;
    pointer-events: none;
    display: none !important;
}

  .custom-swal-width {
    max-width: 800px !important;  /* Establece un ancho máximo de 600px */
  }
.mat-stroked-button {
    border-color: #f09502 !important;
}

.domo-color{
    color: #f09502;
}


  .custom-full-screen-modal .mat-dialog-container {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
    background-color: #181b25; /* Ajusta el color de fondo según tus necesidades */
    color: white;
    padding-left: 10%;
    padding-right: 10%;
    /* Agrega otros estilos personalizados aquí */
  }

  .modal-login-register{
    max-width: 500px !important;
  }

  .modal-login-register .modal-body{
    padding: 1rem !important;
    padding-top: 3rem !important;
  }

  @media screen and (max-width: 500px){
    .modal-login-register{
        max-width: 95% !important;
    }
    .modal-login-register .modal-body{
        padding-top: 1.5rem !important;
    }
}

@media screen and (max-height: 700px) {
    .modal-login-register {
      max-height: 90vh !important; /* Reduce la altura del modal */
    }
/* Personaliza el scroll */
.modal-login-register .modal-body::-webkit-scrollbar {
  width: 12px; /* Ancho del scroll */
}

.modal-login-register .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo del track del scroll */
  border-radius: 10px; /* Bordes redondeados del track */
  border: 1px solid #F09502; /* Borde del track del scroll */
}

.modal-login-register .modal-body::-webkit-scrollbar-thumb {
  background: #F09502; /* Color del thumb del scroll */
  border-radius: 10px; /* Bordes redondeados del thumb */
  border: 1px solid #f1f1f1; /* Borde del thumb del scroll */
}

.modal-login-register .modal-body::-webkit-scrollbar-thumb:hover {
  background: #d48402; /* Color del thumb del scroll al pasar el mouse */
}
  }

.backgroundWhite{
    background-color: white !important;
    min-height: 100vh;
    width: 78.4vw;
    margin-top: -27px !important;
    padding: 40px 60px 0px 45px;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 100000;
}


.swal2-container{
    z-index: 1000000 !important;
}

.mat-typography {
    font-family: "Montserrat", sans-serif !important;
}

.ng-select .ng-select-container {
    width: 185px !important;
}

.mat-body, .mat-body-1, .mat-typography .mat-body, .mat-typography .mat-body-1, .mat-typography {
    font-family: "Montserrat", sans-serif !important;
}

/* Reposive for mobile */
@media screen and (max-width: 767px) {
    .backgroundWhite{
        width: 100vw;
        margin-top: -10px !important;
        padding: 40px 20px 0px 20px;
    }

    body::-webkit-scrollbar, div::-webkit-scrollbar {
        display: none;
    }

    
}